<template>
  <div>
    <Header/>

    <section id="login">
      <div
        class="bg"
        :style="`background-image: url(${require('@/assets/img/account/img_1.jpg')});`">
      </div>
      <div class="bg2"></div>
      <div class="inner w1300">
        <!-- <div class="breadcrumbs">
          <span class="txt-lightgrey">首頁</span> > 登入
        </div> -->

        <div class="login_box">
          <div class="title_box">
            <h4 class="txt-bold">
              登入&nbsp;
              <span class="txt-style-i txt-highbold">Wi-Ho</span>&nbsp;
              <img src="@/assets/img/icon/icon_exclamation.svg" alt="" style="width: .65em;">
            </h4>
            <router-link
              :to="{ name: 'Register' }">
                <p>
                  註冊會員&nbsp;
                  <img src="@/assets/img/icon/icon_next.svg" alt="">
                </p>
            </router-link>
          </div>

          <form action="member_data.php">
            <div class="login_item">
              <p class="txt-greenlake">帳號</p>
              <input
                type="text"
                v-model="loginData.account"
                placeholder="請輸入Email">
            </div>
            <div class="login_item">
              <p class="txt-greenlake">密碼</p>
              <input
                type="password"
                v-model="loginData.password"
                @keyup="loginData.password=loginData.password.replace(/[\u4e00-\u9fa5]/g,'')"
                placeholder="請輸入密碼">
            </div>
            <div class="remember_box">
              <input type="checkbox" name="remember" id="remember" v-model="remember">
              <label for="remember">
                <span></span>
                <p class="txt-grey">記住我</p>
              </label>
              <!-- <div></div> -->
              <router-link
                :to="{ name: 'ForgetPassword' }">
                  <p class="txt-grey">忘記密碼?</p>
              </router-link>
            </div>
            <div class="btnbox txt-right">
              <button
                type="button"
                class="btn btn-blue_blue_line"
                @click="login(loginData);">
                  <img src="@/assets/img/icon/icon_next-gl.svg" alt="">
                  <p>登入</p>
              </button>
            </div>
            <!-- <div class="openidbox">
              <h6 class="p">或使用社群帳號登入</h6>
              <button type="button" class="btn btn-fb">
                <img src="@/assets/img/icon/facebook-01.svg" alt="">
                <p>Facebook</p>
              </button>
              <button type="button" class="btn btn-google">
                <img src="@/assets/img/icon/google.png" alt="">
                <p> Google</p>
              </button>
            </div> -->
          </form>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions, mapMutations } from 'vuex';
import Header from '@/components/Header.vue';
import { checkStorage, setStorage } from '@/utils/timerStorage';

import requestApi from '@/lib/http';
import '@/assets/scss/login.scss';

export default {
  name: 'Login',
  components: {
    Header,
  },
  data() {
    return {
      remember: false,
      loginData: {
        account: '',
        password: '',
      },
    };
  },
  computed: {
    ...mapState([
      'user',
    ]),
  },
  methods: {
    ...mapActions({
      actionHandleLoginInfo: 'handleLoginInfo',
    }),
    ...mapMutations([
      'setMemberInfo',
    ]),
    checkMemberInfo(data) {
      let check = true;
      if (data) {
        if (Object.keys(data).length > 0) {
          Object.keys(data).forEach((key) => {
            if (
              key === 'account'
              || key === 'name'
              || key === 'sex'
              || key === 'birthday'
              || key === 'email'
              || key === 'country_code'
              || key === 'mobile'
              || key === 'county'
              || key === 'district'
              || key === 'address'
            ) {
              if (data[key] === '' || data[key] === null) {
                check = false;
              }
            }
          });
          this.setMemberInfo(true);
          setStorage('memberInfo', check);
        }
      }
      return check;
    },
    async login(params) {
      const { status, message, token } = await requestApi('member.login', params);

      if (status) {
        // const { token = false } = result?.data ?? {};
        const isSetup = await this.actionHandleLoginInfo({ token });
        if (isSetup) {
          if (this.remember) {
            window.localStorage.setItem('rememberAccount', this.loginData.account);
          } else {
            window.localStorage.removeItem('rememberAccount');
          }
          this.$customSWAL({
            title: '登入成功',
            thenFunc: () => {
              /* 檢查是否有取得會員資料 */
              if (this.user) {
                /* 檢查會員是否有填寫完整會員資料 */
                const checkMemberInfo = this.checkMemberInfo(this.user);
                if (checkMemberInfo) {
                  /* 檢查是否回到上一頁 */
                  if (this.$route.query) {
                    const { pathname, search } = this.$route.query;
                    if (pathname && search) {
                      this.$router.push({
                        path: `${pathname}${search}`,
                      });
                    } else if (pathname) {
                      this.$router.push({
                        path: pathname,
                      });
                    } else {
                      /* 如果沒有,回到首頁 */
                      this.$router.push({
                        name: 'Home',
                      });
                    }
                  } else {
                    /* 例外情況到首頁 */
                    this.$router.push({
                      name: 'Home',
                    });
                  }
                } else {
                  /* 如果沒有填寫資料,前往會員資料 */
                  this.$router.push({
                    name: 'MemberData',
                  });
                }
              } else {
                /* 沒有接到會員資料,到首頁 */
                this.$router.push({
                  name: 'Home',
                });
              }
            },
          });
        }
      } else {
        this.$customSWAL({
          icon: 'error',
          title: message,
        });
      }
    },
    checkIfLogin() {
      if (checkStorage('token')) {
        this.$router.push({
          name: 'Home',
        });
      }
    },
  },
  mounted() {
    this.checkIfLogin();

    if (window.localStorage.getItem('rememberAccount')) {
      this.loginData.account = window.localStorage.getItem('rememberAccount');
      this.remember = true;
    }
  },
};
</script>
